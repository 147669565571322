// Based on compiled Pano2VR virtual tour.
// 1. pano2vrPlayer.js should be imported in App level.
// 2. Pass in pano skin and config into this component.

import './PanoContainer.css';

import React, { useCallback, useEffect } from 'react';

import { styled } from '@mui/material/styles';

import type { PanoContainerProps } from './PanoContainer.types';

const PanoContainerRoot = styled('div')({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'fixed',
});

const PanoContainerHack = styled('div')({
  width: 1,
  height: 1,
});

const PanoContainer: React.FC<PanoContainerProps> = ({
  baseURL,
  children,
  containerID,
  panoConfigXMLString,
  panoSkinFn,
  startNode,
  onNodeChange,
}) => {
  const handleOnNodeChange = useCallback(
    (Pano) => {
      onNodeChange?.(Pano.getCurrentNode());
    },
    [onNodeChange]
  );

  useEffect(() => {
    // Initializes Pano instance.
    const Pano = new window.pano2vrPlayer(containerID);

    // Initializes Pano skin, with `this` bind to window.
    panoSkinFn.apply?.(window, [Pano, baseURL]);

    // Configure Pano instance.
    Pano.readConfigString(panoConfigXMLString);

    Pano.addListener('changenode', () => handleOnNodeChange(Pano));

    if (startNode) {
      // Set start node.
      Pano.openNext(`{${startNode}}`);
    }

    return () => {
      Pano.removeEventListener('changenode', () => handleOnNodeChange(Pano));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PanoContainerRoot id={containerID} />
      {/* <!-- Hack needed to hide the url bar on iOS 9, iPhone 5s -->  */}
      <PanoContainerHack />
      {children}
    </>
  );
};

export default PanoContainer;
